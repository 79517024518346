<template>
  <div class="home">
    <div class="renderer-holder w-full">
      <pre ref="renderer" class="leading-tight select-none text-xs lg:text-sm max-w-screen-lg m-auto overflow-hidden">
        <pre ref="rendererIn"></pre>
      </pre>
    </div>


    
  </div>

  
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import {DiffDOM, stringToObj} from 'diff-dom'

import frames from './trimmed'


export default {
  name: 'Home',
  data() {
    return {
      numberOfFrames: frames.length,
      currentFrameIndex: 0,
      publicPath: process.env.BASE_URL,
      dd: new DiffDOM(),
    }
  },
  methods: {
    play() {
      
      // console.log('moving counter', this.currentFrameIndex)
      this.currentFrameIndex = (this.currentFrameIndex + 1 ) % this.numberOfFrames
      // const diff = this.dd.diff(this.$refs.rendererIn, stringToObj('<pre>' + frames[this.currentFrameIndex] + '</pre>'))
      // console.log(diff)
      // this.dd.apply(this.$refs.rendererIn, diff)
      this.$refs.renderer.innerHTML = frames[this.currentFrameIndex]
      window.setTimeout(this.play, 350)
    }
  },
  mounted() {
    this.play()
  },
  computed: {
    currentFrame() {
      return frames[this.currentFrameIndex]
    },
  },

  components: {
    HelloWorld
  }
}
</script>

<style lang="stylus">

.renderer-holder {
  background-color: rgb(46, 49, 49);
  // text-align: center;

}

pre {
  background-color: rgb(46, 49, 49);
  text-align: center;
}

@media (max-width: 700px) { 
  pre {
    font-size: 5px !important;
  }
 }

.underlined {
  text-decoration: underline;
  text-decoration-thickness: 4px;
  text-decoration-color: rgba(254, 202, 202, 1)
}

.underlined-blue {
  text-decoration: underline;
  text-decoration-thickness: 4px;
  text-decoration-color: #3daabb;
}

.project-demo {
  background-color: rgb(46, 49, 49);
}

.ff-background {
  background-color: #3daabb;
}

.ff-text {
  // color: #00c8d7;
  // background-color: black;
}
</style>
